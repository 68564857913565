import styled, {css} from "styled-components";
import React from "react"
import fbIcon from './../../images/facebook.svg'
import twitterIcon from './../../images/twitter.svg'
import emailIcon from './../../images/email.svg'
import Layout from "../layout";
import HeaderSection from "../sections/HeaderSection";
import MyContainer from "../containers/MyContainer";
import FooterSection from "../sections/FooterSection";
import ReactMarkdown from "react-markdown"
import moment from "moment";
import SEO from "../seo";
import {CmsUtils} from "../../utils/cmsUtils";
import {graphql, useStaticQuery} from "gatsby";
import {useLanguageHook} from "../../hooks/useLanguageHook";

export default function BlogPostTemplate(props) {
    const intl = useLanguageHook();
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author,
                        keywords,
                        releaseDate,
                        siteUrl
                    }
                }
            }
        `
    )

    const {pageContext} = props;
    const {Page, Content, updated_at, published_at} = pageContext;
    console.log('Log:> [BlogPostTemplate.js] :=', props);
    const {
        content_en,
        content_ru,
        cover_img_ru,
        cover_img_en

    } = Content;

    const {
        page_url_en,
        page_url_ru,
        seo_description_en,
        seo_description_ru,
        seo_keywords_en,
        seo_keywords_ru,
        title_en,
        title_ru,
        seo_img_en,
        seo_img_ru,
    } = Page;

    const share = (type) => {
        const url = window.location.href;
        switch (type) {
            case 'facebook':
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
                break;
            case 'twitter':
                window.open(`https://twitter.com/share?url=${url}`, '_blank');
                break;
            case 'email':
                window.open(`mailto:?subject=Check Subtitles.love&body=${url}`, '_blank');
                break;
        }
    }

    const title = process.env.GATSBY_LANG_ENV === "en" ? title_en : title_ru;
    const content = process.env.GATSBY_LANG_ENV === "en" ? content_en : content_ru;
    const seo_description = process.env.GATSBY_LANG_ENV === "en" ? seo_description_en : seo_description_ru;
    const seo_keywords = process.env.GATSBY_LANG_ENV === "en" ? seo_keywords_en : seo_keywords_ru;
    const seo_img = process.env.GATSBY_LANG_ENV === "en" ? seo_img_en : seo_img_ru;
    const url = process.env.GATSBY_LANG_ENV === "en" ? page_url_en : page_url_ru;
    const coverImg = process.env.GATSBY_LANG_ENV === "en" ? cover_img_en : cover_img_ru;

    const seoTitle = `${title} » ${site.siteMetadata.author}`


    return(
        <Layout>
            <SEO
                title={seoTitle}
                description={seo_description}
                tagKeywords={seo_keywords}
                pageKeywords={seo_keywords}
                pageUrl={`${site.siteMetadata.siteUrl}/blog/${url}`}
                publishedDate={published_at}
                updateDate={updated_at}
                imgUrl={CmsUtils.imageUrl(seo_img.url)}
                imgType={seo_img.mime}
                imgHeight={seo_img.height}
                imgWidth={seo_img.width}
            />

            <BaseWrapper>
                <HeaderSection/>
                <MyContainer>
                    <Wrapper>
                        <PostImg src={CmsUtils.imageUrl(coverImg != null ? coverImg.url : '')}/>
                        <BlogTitle>
                            <h2>{title}</h2>
                        </BlogTitle>
                        <BlogPostInfo>
                            <BlogShare>
                                <BlogShareTitle>{intl.formatMessage({id: 'actions.share'})}</BlogShareTitle>
                                <SocialContainer>
                                    <SocialItem icon={emailIcon} onClick={() => share('email')}/>
                                    <SocialItem icon={fbIcon} onClick={() => share('facebook')}/>
                                    <SocialItem icon={twitterIcon} onClick={() => share('twitter')}/>
                                </SocialContainer>
                            </BlogShare>
                            <BlogDate>
                                <BlogDateTitle>{intl.formatMessage({id: 'actions.date'})}</BlogDateTitle>
                                <Date>{moment(updated_at).format('DD/MM/YYYY')}</Date>
                            </BlogDate>
                        </BlogPostInfo>
                        <BlogPost>
                            <ReactMarkdown escapeHtml={false}  source={content}/>
                        </BlogPost>
                    </Wrapper>
                </MyContainer>
                <FooterSection/>
            </BaseWrapper>
        </Layout>

    )
}


const BaseWrapper = styled.div`
    font-family: Avenir Next Cyr;
    overflow: hidden;
`;


const Wrapper = styled.div`
    padding-top: 17px;
    padding-bottom: 37px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 730px;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin: auto;
    
`;

const PostImg = styled.div`
  width: 100%;
  height: 417px;
  background-color: #20202b;
  ${props => props.src && css `
    background-image: url(${props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `}
`;

const BlogTitle = styled.div`
      text-align: center;
      color: #FFFFFF;
      font-weight: 750;
      font-size: 28px;
      width: 90%;
      margin: auto;
      padding: 5px 20px;
      box-sizing: border-box;
`;

const BlogPostInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 30px;
   @media (max-width: 860px) {
      padding: 0 30px;
      box-sizing:  border-box;
    }
`;

const BlogShare = styled.div`
  flex: 1;
  text-align: left;
`;

const BlogShareTitle = styled.div`
 font-size: 14px;
 color: #C4C4C4;
 text-transform: uppercase;
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 70px;
  margin-top: 10px;
`;

const SocialItem = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
  
  ${props => props.icon && css`
    background-image: url(${props.icon});
    background-position: center;
    background-repeat: no-repeat;
  `}
`;


const BlogDate = styled.div`
  flex: 1;
  text-align: right;
`;

const BlogDateTitle = styled.div`
  color: #C4C4C4;
 font-size: 12px;
 text-transform: uppercase;
`;

const Date = styled.div`
 color: #FFFFFF;
 font-size: 18px;
   margin-top: 10px;

`;

const BlogPost = styled.div`
  margin-top: 32px;
  color: #EFEFEF;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
   @media (max-width: 860px) {
      padding: 0 30px;
      box-sizing:  border-box;
    }
`;
